import { Box } from "@mui/material"

const Flex = ({children, style, ...props}) => (
  <Box 
    style={{
      display: 'flex',
      ...style
    }}
    {...props}
  >
    {children}
  </Box>
)

export default Flex