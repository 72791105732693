// Sample page: https://golddogstudios.photoshelter.com
import Typography from '../components/Typography';
import Flex from '../components/Flex';
import Link from '../components/Link';
import albums from '../Albums.json';


const Home = () => (
  <Flex style={{justifyContent: 'center'}}>
    {albums.map(({title, s3Bucket, cover}) => (
      <Link href={`/album/${s3Bucket}`}>
        <Flex key={s3Bucket} style={{flexDirection: 'column', alignItems: 'center'}}>
          <img src={cover} alt={title} style={{height: '200px'}}/>
          <Typography>
            {title}
          </Typography>
        </Flex>
      </Link>
    ))}
  </Flex>
)

export default Home
