import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Header from "./components/Header";
import Album from "./pages/Album";


const routes = [
  {
    name: 'Home',
    path: '/',
    element: <Home/>,
  }, {
    name: 'About',
    path: '/about',
    element: <About/>,
  }, {
    name: 'Contact',
    path: '/contact',
    element: <Contact/>,
  }, {
    path: '/album/:s3Bucket',
    element: <Album/>,
  }
]

const App = () => (
  <Router>
    <Header routes={routes}/>
    <Routes>
      {routes.map(({name, path, element}) => (
        <Route key={name} path={path} element={element} />
      ))}
    </Routes>
  </Router>
);

export default App;
