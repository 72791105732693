// Icons from: https://www.freepik.com/icons/social-media
// https://www.iconpacks.net/free-icon/venmo-15302.html

import Link from '../components/Link'
import Flex from '../components/Flex'
import Typography from '../components/Typography'
import Facebook from '../assets/social/facebook.png'
import Instagram from '../assets/social/instagram.png'
import Venmo from '../assets/social/venmo.png'

const MediaItem = ({icon, alt, handle, href}) => (
  <Link href={href} target="_blank">
    <Flex style={{justifyContent: 'center',  marginBottom: 16}}>
    <Flex style={{alignItems: 'center', width: '250px'}}>
      <img src={icon} alt={alt} style={{height: '60px', width: '60px'}}/>
      <Typography variant="h6" fontWeight='bold'>
        <Flex style={{marginLeft: 8}}> {handle} </Flex>
      </Typography>
    </Flex>
    </Flex>
  </Link>
)



const Contact = () => (
  <Flex style={{ display:'flex', justifyContent: 'center', flexDirection: 'column'}}>
    <MediaItem icon={Facebook} alt='Facebook' handle='McClaineMedia' href="https://www.facebook.com/McClaineMedia/"/>
    <MediaItem icon={Instagram} alt='Instagram' handle='mcclaine_media' href="https://www.instagram.com/mcclaine_media/"/>
    <MediaItem icon={Venmo} alt='Venmo' handle='Heather-McClaine' href="https://account.venmo.com/u/Heather-McClaine"/>
  </Flex>
)
export default Contact