import { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import Flex from '../components/Flex';
import Typography from '../components/Typography';
import Popper from '../components/Popper';
import { useParams } from 'react-router-dom';
import albums from '../Albums.json'

// const BUCKET_NAME = '2024-07-13.erie-hot-air-balloon-festival';

const Album = () => {
  const { s3Bucket } = useParams();
  const [selectedImg, setSelectedImg] = useState()

  const title = albums.filter((a) => a.s3Bucket === s3Bucket)[0].title
  const [objects, setObjects] = useState([]);

  useEffect(() => {
    // Configure AWS SDK with Cognito Identity Pool
    AWS.config.update({
      region: process.env.REACT_APP_BUCKET_REGION,
    });

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    });

    // Refresh credentials to ensure they are properly loaded
    AWS.config.credentials.get((err) => {
      if (err) {
        console.error("Error loading credentials", err);
        return;
      }

      // Initialize AWS S3 after credentials are loaded
      const s3 = new AWS.S3();

      // List Objects from a specific bucket
      const params = {
        Bucket: s3Bucket,
      };

      s3.listObjects(params, (err, data) => {
        if (err) {
          console.log("Error", err);
        } else {
          setObjects(data.Contents);
        }
      });
    });
  }, [s3Bucket]);

  return (
    <>
      <Flex style={{ width: '100%', justifyContent: 'center'}}>
        <Typography variant="h2" >{title}</Typography>
      </Flex>
      <Flex style={{ padding: '32px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        {objects.map((object) => (
          <img
            key={object.Key}
            src={`https://s3.amazonaws.com/${s3Bucket}/${object.Key}`} 
            alt={object.Key}
            style={{ height: '200px', padding: '4px', cursor: 'zoom-in' }}
            onClick={() => setSelectedImg(object.Key)}
          />
        ))}
      </Flex>
      <Popper open={selectedImg}>
        <Flex style={{
          background: 'black',
          height: '100vh',
          width: '100vw',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <img 
            src={`https://s3.amazonaws.com/${s3Bucket}/${selectedImg}`} 
            alt={selectedImg}
            style={{
              maxHeight: '90vh',
              maxWidth: '90vw',
              cursor: 'zoom-out'
            }}
            onClick={() => setSelectedImg()}
          />
        </Flex>
      </Popper>
    </>
  );
};

export default Album;
